/* eslint-disable no-constant-condition */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Api from "../../assets/js/utils/Api";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";

import SimpleLayout from "pages/User/components/SimpleLayout";

import Grid from "@mui/material/Grid";
import { useMaterialUIController } from "context";
import ProductImages from "components/ProductImages";
import ProductInfo from "components/ProductInfo";
import GoogleMap from "../../components/GoogleMap";

const Product = () => {
    const [controller] = useMaterialUIController();
    const { authorized, user } = controller;
    const navigate = useNavigate();
    
    const { product_id } = useParams();
    
    const [state, setState] = React.useState({
        productId: product_id,
        response: null,
        loading: false,
        showError: false, 
        errorMessage: null,
    });

    React.useEffect(() => {
        // if(!authorized){
        //     return navigate("/login", { replace: true });
        // }
        if(state.productId === product_id){
            if(!state.productId || state.loading){
                return;
            }
        }
        loadProduct();
    }, [product_id]);

    function loadProduct(){
        const source = axios.CancelToken.source();
        let id = state.productId;
        if(!id){
            return;
        }
        
        setState({
            ...state,
            loading: true
        });

        const requestData = {
            id: id
        };
        Api.getProduct(requestData, source).then(data => {
            setState({
                ...state,
                response: data,
                loading: false,
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    ...state,
                    loading: false,
                    showError: true, 
                    errorMessage: errors                    
                });
            }
        });        
    }

    return (
        <SimpleLayout wide="full">
            <MKBox py={3} pt={0}>
                {
                    state.response !== null ?
                        <>
                            <MKBox mb={3}>
                                <MKTypography variant="h5" fontWeight="medium">
                                    Product Details
                                </MKTypography>
                            </MKBox>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6} xl={5}>
                                    <ProductImages image={state.response.image} />
                                </Grid>
                                <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                                    {
                                        state.response !== null ?
                                            <ProductInfo product={state.response} />
                                        :
                                            <></>
                                    }
                                </Grid>
                            </Grid>
                            <MKBox mt={1} mb={2}>
                                <MKTypography variant="h6" fontWeight="medium">
                                    Location
                                </MKTypography>
                            </MKBox>
                            {
                                state.response.store.latitude ?
                                    <GoogleMap
                                        lat={state.response.store.latitude}
                                        lng={state.response.store.longitude}
                                        address={(state.response.store.title+" ("+state.response.store.address+")")}
                                    />
                                :
                                null
                            }
                        </>
                    :
                    null
                }
                {
                    state.loading ?
                        <Box sx={{textAlign: "center"}}>
                            <CircularProgress color="info" size={32} />
                        </Box>
                    :
                    null
                }
            </MKBox>
        </SimpleLayout>
    );
}

export default Product;
